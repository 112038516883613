import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'toLogin',
    meta: {
      title: "登录"
    },
    component: () => import("@/views/loginView.vue")
  },
  {
    path: '/login',
    name: 'toLogin',
    meta: {
      title: "登录"
    },
    component: () => import("@/views/loginView.vue")
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "404"
    },
    component: () => import("@/views/404View.vue")
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: "403"
    },
    component: () => import("@/views/403View.vue")
  },
  {
    path:"/test",
    meta:{
      title:"测试页面"
    },
    component: () => import("@/views/testView.vue")
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import("@/views/admin/adminView.vue"),
    children: [
      {
        path: "",
        name: "controlPanel",
        meta: {
          title: "乐梦星音乐艺术中心"
        },
        component: () => import("@/views/admin/controlView.vue")
      },
      {
        path: 'blank',
        name: 'blank',
        meta: {
          title: "规划中"
        },
        component: () => import("@/views/admin/blankView.vue")
      },
      {
        path: 'courseTable',
        meta: {
          title: "教师课表"
        },
        component: ()=>import("@/views/admin/courseTable.vue")
      },
      {
        path: 'quotaHours',
        meta: {
          title: "课时列表"
        },
        component: ()=>import("@/views/admin/quotaHours.vue")
      },
      {
        path: 'student',
        meta: {
          title: "学生列表"
        },
        component: ()=>import("@/views/admin/studentList.vue")
      }, {
        path: 'teacher',
        meta: {
          title: "教师列表"
        },
        component: ()=>import("@/views/admin/teacherList.vue")
      }, {
        path: 'homework',
        meta: {
          title: "学生作业"
        },
        component: ()=>import("@/views/admin/homework.vue")
      }, {
        path: 'leave',
        meta: {
          title: "请假记录"
        },
        component: ()=>import("@/views/admin/leaveList.vue")
      }, {
        path: 'appointment',
        meta: {
          title: "预约记录"
        },
        component: ()=>import("@/views/admin/appointment.vue")
      },
      {
        path: 'swiper',
        name: 'swiper',
        meta: {
          title: "轮播图管理"
        },
        component: () => import("@/views/admin/swiperView.vue")
      },
      {
        path: 'addSwiper',
        name: 'addSwiper',
        meta: {
          title: "添加轮播图"
        },
        component: () => import("@/views/admin/addSwiperView.vue")
      },
      {
        path: 'attendence',
        meta: {
          title: "考勤管理"
        },
        component: () => import("@/views/admin/attendenceList.vue")
      }, {
        path: 'clock',
        meta: {
          title: "打卡记录"
        },
        component: () => import("@/views/admin/clockList.vue")
      }, {
        path: 'teacherIntroduce',
        meta: {
          title: "教师简介"
        },
        component: () => import("@/views/admin/teacherIntroduce.vue")
      },{
        path: 'addTeacherIntroduce',
        meta: {
          title: "教师简介"
        },
        component: () => import("@/views/admin/addTeacherIntroduce.vue")
      },
       {
        path: 'introduction',
        meta: {
          title: "机构简介"
        },
        component: () => import("@/views/admin/introduction.vue")
      }, {
        path: 'coupons',
        meta: {
          title: "优惠记录"
        },
        component: () => import("@/views/admin/coupons.vue")
      }, {
        path: "course",
        meta: {
          title: "课程管理"
        },
        component: () => import("@/views/admin/course.vue")
      }, {
        path: "setting",
        meta: {
          title: "系统设置"
        },
        component: () => import("@/views/admin/setting.vue")
      }, {
        path: "courseRecommend",
        meta: {
          title: "课程推荐"
        },
        component: () => import("@/views/admin/courseRecommend.vue")
      },
      {
        path: "addRecommend",
        meta: {
          title: "课程推荐"
        },
        component: () => import("@/views/admin/addRecommend.vue")
      },
      {
        path:"editRecommendCourse",
        meta:{
          title:"编辑课程",
        },
        component: () => import("@/views/admin/editRecommendCourse.vue")
      },
      {
        path: 'usersetting',
        meta: {
          title: "密码修改"
        },
        component: () => import("@/views/admin/user-setting.vue")
      },
      {
        path:"plans",
        meta:{
          title:"待办事项"
        },
        component: () => import("@/views/admin/planView.vue")
      },
      {
        path:"remind",
        meta:{
          title:"课时提醒"
        },
        component:() => import("@/views/admin/remindView.vue")
      },
      {
        path:"relation",
        meta:{
          title:"用户关联列表"
        },
        component:()=> import("@/views/admin/relationView.vue")
      },
      {
        path:"ymx_course",
        meta:{
          title:"公益课报名名单"
        },
        component:()=> import("@/views/admin/ymxCourseView.vue")
      },
      {
        path:"ymx_courseTime",
        meta:{
          title:"公益课课程"
        },
        component:()=> import("@/views/admin/ymx_courseTimeView.vue")
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    return next({ path: '/admin', replace: true })
  }
  if (to.matched.length == 0) {
    next({ path: '/404' })
  } else {
    if (to.path !== '/login') {
      var token = Cookies.get("token")
      var username = Cookies.get("username")
      if (token == null || token == '' || token == undefined || username == null || username == '' || username == undefined) {
        Cookies.remove("token")
        Cookies.remove("username")
        return next({ path: '/login', replace: true })
      }
    }
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  }

})

export default router
